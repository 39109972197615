import { CollectionInfo } from "@/app/_components/CollectionCard/CollectionInfo"
import { LazyImage } from "@/app/_components/LazyImage"
import { UserIcon } from "@/app/_components/UserIcon"
import { adjustmentFill } from "@/app/_utils/array"
import clsx from "clsx"
import { useMemo } from "react"
import { UserIconName } from "../UserIconName"
import * as styles from "./index.css"

export interface CollectionPhotoUser {
  id: number
  name: string
  iconUrl: string
  layout?: string
  style?: string
}

export interface CollectionPhoto {
  id: number
  hashId: string
  imageUrl: string
  user: CollectionPhotoUser
  // TODO: item座標や価格
  // items?: []
}

interface Props {
  size?: "xsmall" | "small" | "medium" | "large"
  horizontal?: boolean
  fixed?: boolean
  title: string
  linkUrl: string
  photoCount?: number
  mainPhoto: CollectionPhoto | undefined
  subPhotos: CollectionPhoto[]
  mainPhotoUser: CollectionPhotoUser
  collectionInfo?: "basic" | "user" | "monitor"
  users?: {
    id: number
    name: string
    iconUrl: string
  }[]
  subText?: string
  className?: string
  gtmClass?: string
}

export function CollectionCard({
  size = "medium",
  horizontal = false,
  fixed = false,
  title,
  linkUrl,
  photoCount,
  mainPhoto,
  subPhotos,
  mainPhotoUser,
  users,
  collectionInfo = "basic",
  subText,
  className = "",
  gtmClass
}: Props) {
  // largeは3枚、mediumは2枚、smallは1枚、xsmallは0枚
  const displaySubPhotoCount = useMemo(() => {
    return size === "large"
      ? 3
      : size === "medium"
        ? 2
        : size === "small"
          ? 1
          : 0
  }, [size])

  const subPhotoNotFoundImages = useMemo(() => {
    if (subPhotos.length >= displaySubPhotoCount) {
      return []
    }
    return adjustmentFill([], displaySubPhotoCount - subPhotos.length)
  }, [subPhotos, displaySubPhotoCount])

  return (
    <a
      href={linkUrl}
      className={clsx(
        styles.cardContainer,
        size === "large" && styles.large,
        size === "medium" && styles.medium,
        size === "small" && styles.small,
        size === "xsmall" && styles.xsmall,
        horizontal && styles.horizontal,
        fixed && styles.fixed,
        className,
        gtmClass
      )}
    >
      <div
        className={clsx(
          styles.photosContainer,
          size === "large" && styles.large,
          size === "medium" && styles.medium,
          size === "small" && styles.small,
          size === "xsmall" && styles.xsmall,
          horizontal && styles.horizontal
        )}
      >
        <div
          className={clsx(
            styles.mainPhotoWrapper,
            size === "large" && styles.large,
            size === "medium" && styles.medium,
            size === "small" && styles.small,
            size === "xsmall" && styles.xsmall,
            horizontal && styles.horizontal
          )}
        >
          {size === "small" && collectionInfo === "user" && (
            <div
              className={clsx(
                styles.iconNameWrapper,
                styles.small,
                horizontal && styles.horizontal
              )}
            >
              <UserIcon
                name={mainPhotoUser.name}
                imageUrl={mainPhotoUser.iconUrl}
                size="xsmall"
              />
            </div>
          )}
          {size !== "xsmall" && collectionInfo === "basic" && (
            <div
              className={clsx(
                styles.iconNameWrapper,
                size === "large" && styles.large,
                size === "small" && styles.small,
                horizontal && styles.horizontal
              )}
            >
              <UserIconName
                name={mainPhotoUser.name}
                imageUrl={mainPhotoUser.iconUrl}
                layout={
                  size === "large" || size === "medium"
                    ? mainPhotoUser.layout
                    : undefined
                }
                style={
                  size === "large" || size === "medium"
                    ? mainPhotoUser.style
                    : undefined
                }
                size={
                  size === "large" || size === "medium" ? "small" : "xsmall"
                }
                color="base00"
                isBold
              />
            </div>
          )}

          {mainPhoto === undefined ? (
            <LazyImage
              className={styles.photoImage}
              src="https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/blank/Img_Blank_Photo.svg"
              alt="デフォルト画像"
            />
          ) : (
            <LazyImage
              className={styles.photoImage}
              src={mainPhoto.imageUrl}
              srcset={mainPhoto.imageUrl}
              alt={`${mainPhotoUser.name}さんの実例写真`}
            />
          )}
        </div>

        {size === "small" && (
          <>
            <div
              className={clsx(
                styles.dummy,
                styles.back,
                horizontal && styles.horizontal
              )}
            >
              <div className={styles.dummyCover} />
            </div>
            <div
              className={clsx(
                styles.dummy,
                styles.center,
                horizontal && styles.horizontal
              )}
            >
              <div className={styles.dummyCover} />
            </div>
          </>
        )}

        {displaySubPhotoCount > 0 && size !== "small" && (
          <>
            {subPhotos.slice(0, displaySubPhotoCount).map(photo => (
              <div key={photo.id} className={clsx(styles.subPhotoWrapper)}>
                <LazyImage
                  className={styles.photoImage}
                  src={photo.imageUrl}
                  srcset={photo.imageUrl}
                  alt={`${photo.user.name}さんの実例写真`}
                />
              </div>
            ))}
            {subPhotoNotFoundImages.map((_, index) => (
              <div
                // biome-ignore lint/suspicious/noArrayIndexKey: 仕方なし
                key={index}
                className={clsx(styles.subPhotoWrapper)}
              >
                <LazyImage
                  className={styles.photoImage}
                  src="https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/blank/Img_Blank_Photo.svg"
                  alt="デフォルト画像"
                />
              </div>
            ))}
          </>
        )}
      </div>

      <CollectionInfo
        type={collectionInfo}
        size={size}
        title={title}
        photoCount={photoCount}
        subText={subText}
        mainPhotoUser={mainPhotoUser}
        users={users}
      />
    </a>
  )
}
