import { LineClamp } from "@/app/_components/LineClamp"
import { UserIcon } from "@/app/_components/UserIcon"
import clsx from "clsx"
import { useMemo } from "react"
import * as styles from "./index.css"

interface Props {
  type: "basic" | "user" | "monitor"
  size?: "xsmall" | "small" | "medium" | "large"
  title: string
  subText?: string
  monitorTitle?: string
  photoCount?: number
  mainPhotoUser:
    | {
        id: number
        name: string
        iconUrl: string
        layout?: string
        style?: string
      }
    | undefined
  users?: {
    id: number
    name: string
    iconUrl: string
  }[]
}

export function CollectionInfo({
  type,
  size,
  title,
  subText,
  monitorTitle,
  photoCount,
  mainPhotoUser,
  users
}: Props) {
  // サーチの使用上、上限が10,000枚になるため、10,000枚以上の場合は「10,000枚以上」に変換
  const photoCountText = useMemo(() => {
    if (photoCount === undefined || photoCount === null) {
      return ""
    }

    return photoCount >= 10000
      ? "10,000枚以上からセレクト"
      : `${photoCount.toLocaleString()}枚からセレクト`
  }, [photoCount])

  const displayMainText = useMemo(() => {
    if (type === "user" && mainPhotoUser !== undefined) {
      return mainPhotoUser.name
    }
    if (type === "monitor") {
      return monitorTitle || ""
    }
    return title
  }, [type, mainPhotoUser, title, monitorTitle])

  const displaySubText = useMemo(() => {
    if (type === "user" && mainPhotoUser !== undefined) {
      return [mainPhotoUser.layout, mainPhotoUser.style]
        .filter(meta => meta !== null && meta !== undefined && meta !== "")
        .join("｜")
    }
    // subTextがあれば表示、なければ枚数を表示
    return subText || photoCountText
  }, [type, subText, photoCountText, mainPhotoUser])

  const displayUsers = useMemo(() => {
    return type === "monitor"
      ? []
      : type === "user"
        ? size === "small" || mainPhotoUser === undefined
          ? // small userのときだけユーザーアイコンは非表示
            []
          : [mainPhotoUser]
        : users
  }, [users, size, type, mainPhotoUser])

  return (
    <div
      className={clsx(
        styles.cardDescription,
        size === "small" && styles.small,
        size === "xsmall" && styles.xsmall
      )}
    >
      <div className={styles.textContainer}>
        {displayMainText !== "" && (
          <LineClamp
            text={displayMainText}
            line={1}
            className={clsx(
              styles.title,
              size === "large" && styles.largeTitle,
              size === "medium" && styles.mediumTitle,
              size === "small" && styles.smallTitle,
              size === "xsmall" && styles.xsmallTitle
            )}
          />
        )}
        {displaySubText !== "" && (
          <LineClamp
            text={displaySubText}
            line={1}
            className={styles.subText}
          />
        )}
      </div>

      {displayUsers && displayUsers.length > 0 && (
        <div
          className={clsx(
            styles.cardUser,
            (size === "small" || size === "xsmall") && styles.small
          )}
        >
          <div
            className={clsx(
              styles.iconsWrapper,
              size === "large" && styles.large,
              size === "small" && styles.small,
              size === "xsmall" && styles.xsmall
            )}
          >
            {displayUsers.map(user => (
              <UserIcon
                key={user.id}
                name={user.name}
                imageUrl={user.iconUrl}
                size={
                  size === "large"
                    ? "medium"
                    : size === "medium"
                      ? "small"
                      : "xsmall"
                }
                withBorder
                className={clsx(
                  styles.userIconMargin,
                  size === "large" && styles.large,
                  size === "small" && styles.small,
                  size === "xsmall" && styles.xsmall
                )}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
