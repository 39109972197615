import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FCollectionCard%2Findex.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2BVX0W6rOBB971dYV7pXjVS3kFBCuC%2F3Vww2xC2xke2kaVf995WNIRgbkmj3YaWV2rqGmfGZmTPj4flUnaVIUvDXAwCYyrZBnzmoBcW%2FH4BZoSKHtkGKwJI3xwOTOYgr4b8V%2FEPmAB0VH96jNgdZe9b%2FK3JWEJOSC6QoZzlgnBH9ojwKyUUOWk6ZIkbvg2K1z0EcRT%2F1dk9ovVf9%2Fvvh2UK269pAN2cl%2BixPYDMRmHXLwT7jWwiAXV%2FNOT34NHLAbO8KsCAtQepx%2FaThrIZYxh38ggtMBBQI06PMwQmJRwgVbZhq43cjzU9EVA3%2FyMGeYkyYyYBATNIu%2BI4KXo1x2jXqgrYIb9PD87S7nLS8P0%2BQBil6MgkfYlA0vHwP%2BBO5HlBGFUVN4JTNEkabx6VYvYWgx3ZN%2FlEAojusJJ6VbCF8xlZnIgeyRQys9WMkW1IqaKorBzF4AfHVUrqVE1mOKkWEAVVypghTOfjx47eDERWSN0dFbjiVMkmUzXOByvda8CMzTulGMMZRrbpDTGuA5ESYkn3ruMDzKevEZ%2BPHJwEv%2BrFnouOtK7sBLyC5RiW6ukiYOgWSNxQ7QpvZ0vyClGFyznXOfEwOlQJ%2B2L50ifOkT2VOudjcpLYV9jqp2zuz%2BWoQGntPPD8JSwqBTFimDnZ2E%2BqPadUijCmrzZ1ifpP%2BcuGtpVNDqp5ZonNswrIcNJQRJGCt80iYeoyzCJP6yUlVuQLRzycg6gI9Rk%2FA%2FqwMg8eVsXOoZ%2FHBgivFD15Md25znAhv1o4wci6TmULixZsOZUVVDkpNrFB%2FHUwWd3WVeKGr3NY2iv922yALTPt3Sn3MujsbQTQGatfSADZcXz%2B%2F9qNAx57hgWU93HTboR58a06hGqu9Um90XEbDAZ1Fu%2FWs4ou1CcIJvvQaPhzAl87jc9C52KqFRF86h2M02EYGgyVwLM%2Bl%2BWtcDPg2nTgaK9UzQ%2BP1UTauxHAtoIbWDFJFDjIHUiGhnGYa9aZuq%2BraHbsXhi9P5ZZ57QLL7BpUahnbPErSfSIMlvdugKqGGE%2F0CjEVpOy86Q5xx%2BjBBrUNyusin6ZM9XUBh1b0nIw05Zzm1zVNFcZt0BnH345S0erTd32STi8iykmPZ8ZEZuDAAC51InIMozsgUVMGbUFk7vww%2BTg7Ohejqzk9zUHsiiZByc1VyVNAAmauyAJCOIF4WoAIk6BoCGMv%2BudAMEVAloIQBhDD4PFAGbTX305%2FOa6MevBD1v2u%2Fh6J5Xt9p%2FTbrZUu%2BBnKPcL6phmTdI0MS93pU%2B9%2FOd5OTfTf7Y5m32o6PCNM2QS69TGLOvQX%2FvRP5v3JJrDu9Gtk2J3aRj1Hp73704%2FH83Bqq8xbVFL1OWmZ3QQy0n6z4hVnCkr6RXKw9s9ofKnYTzQLSPm22oDUxpMSAam4l7pG1W2aaap2j9F5YPDrbsLgkPd%2BwkPepzd57%2FsV8n59k%2FfRsvcXN7fp9v%2Fg5pDNh%2B%2B%2FAbknuRAtFAAA%22%7D"
export var back = 'vfxsr4d';
export var cardContainer = 'vfxsr46';
export var cardDescription = 'vfxsr4g';
export var cardUser = 'vfxsr4t';
export var center = 'vfxsr4c';
export var dummy = 'vfxsr4e';
export var dummyCover = 'vfxsr4f';
export var fixed = 'vfxsr45';
export var horizontal = 'vfxsr44';
export var iconNameWrapper = 'vfxsr49';
export var iconsWrapper = 'vfxsr4u';
export var large = 'vfxsr40';
export var largeTitle = 'vfxsr4j';
export var mainPhotoWrapper = 'vfxsr48';
export var medium = 'vfxsr41';
export var mediumTitle = 'vfxsr4l';
export var photoImage = 'vfxsr4a';
export var photosContainer = 'vfxsr47';
export var small = 'vfxsr42';
export var smallTitle = 'vfxsr4n';
export var subPhotoWrapper = 'vfxsr4b';
export var subText = 'vfxsr4s vfxsr4r';
export var textContainer = 'vfxsr4h';
export var title = 'vfxsr4i';
export var userIconMargin = 'vfxsr4v';
export var xsmall = 'vfxsr43';
export var xsmallTitle = 'vfxsr4p';