"use client"

import { showMobile } from "@/app/_assets/styles/media.css"
import { CollectionCard } from "@/app/_components/CollectionCard"
import clsx from "clsx"
import { useMemo } from "react"
import * as styles from "./PageTopCollectionListSp.css"
import type { TopTagCollection } from "./types"

interface Props {
  collections: TopTagCollection[]
  isClassic?: boolean
}

// 配列を指定された列数に分割する
function splitIntoColumns<T>(array: T[], columns: number): T[][] {
  const result: T[][] = Array.from({ length: columns }, () => [])
  array.forEach((item, index) => {
    result[index % columns].push(item)
  })
  return result
}

export function PageTopCollectionListSp({
  collections,
  isClassic = false
}: Props) {
  // large:1枚、medium:1枚、small:2枚、xsmall:14枚
  const [
    largeCardCollections,
    mediumCardCollections,
    smallCardCollections,
    xsmallCardCollections
  ] = useMemo(
    () => [
      collections.slice(0, 1),
      collections.slice(1, 2),
      collections.slice(2, 4),
      collections.slice(4)
    ],
    [collections]
  )

  // xsmallの配列を3列に分割
  const splittedXsmallCardCollections = useMemo(
    () => splitIntoColumns(xsmallCardCollections, 3),
    [xsmallCardCollections]
  )

  if (collections.length === 0) {
    return null
  }

  return (
    <div className={styles.listContainer}>
      {largeCardCollections.length > 0 && (
        <div className={styles.largeCardsContainer}>
          {largeCardCollections.map((collection, index) => (
            <div key={collection.id} className={styles.largeCardWrapper}>
              <CollectionCard
                key={collection.id}
                title={collection.title}
                linkUrl={`/tag/${collection.id}`}
                mainPhoto={collection.mainPhoto}
                subPhotos={collection.subPhotos}
                mainPhotoUser={collection.mainPhoto.user}
                photoCount={collection.photoCount}
                users={collection.users}
                size="large"
              />

              {/* 定番タグコレクション(large)の1個目の後に、サブコピー画像を表示 */}
              {index === 0 && isClassic && (
                <img
                  src="https://web-assets.roomclip.jp/rc_next_web/production/_next/public/images/top/ImgSubCopy.png"
                  alt="RoomClipに投稿された20万人の暮らしから厳選したアイデアをお届けします"
                  className={clsx(styles.subCopyImage, showMobile)}
                />
              )}
            </div>
          ))}
        </div>
      )}

      {mediumCardCollections.length > 0 && (
        <div className={styles.mediumCardsContainer}>
          {mediumCardCollections.map(collection => (
            <CollectionCard
              key={collection.id}
              title={collection.title}
              linkUrl={`/tag/${collection.id}`}
              mainPhoto={collection.mainPhoto}
              subPhotos={collection.subPhotos}
              mainPhotoUser={collection.mainPhoto.user}
              photoCount={collection.photoCount}
              users={collection.users}
              size="medium"
              horizontal
            />
          ))}
        </div>
      )}

      {smallCardCollections.length > 0 && (
        <div className={styles.smallCardsContainer}>
          {smallCardCollections.map(collection => (
            <CollectionCard
              key={collection.id}
              title={collection.title}
              linkUrl={`/tag/${collection.id}`}
              mainPhoto={collection.mainPhoto}
              subPhotos={collection.subPhotos}
              mainPhotoUser={collection.mainPhoto.user}
              photoCount={collection.photoCount}
              size="small"
              users={[]}
            />
          ))}
        </div>
      )}

      {/* 3列の横スクロール */}
      {xsmallCardCollections.length > 0 && (
        <div className={styles.xsmallCardsScroll}>
          {splittedXsmallCardCollections.map((collections, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: 仕方ない
            <div key={index} className={styles.scrollList}>
              <div className={styles.itemWrapper}>
                {collections.map(collection => (
                  <div key={collection.id} className={styles.xsmallCard}>
                    <CollectionCard
                      linkUrl={`/tag/${collection.id}`}
                      mainPhoto={collection.mainPhoto}
                      subPhotos={collection.subPhotos}
                      mainPhotoUser={collection.mainPhoto.user}
                      photoCount={collection.photoCount}
                      title={collection.title}
                      size="xsmall"
                      users={[]}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
